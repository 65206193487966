"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
function Svg(props) {
    return ((0, jsx_runtime_1.jsxs)("svg", __assign({ id: "Layer_1", xmlns: "http://www.w3.org/2000/svg", height: "100%", width: "100%", xmlnsXlink: "http://www.w3.org/1999/xlink", x: "0px", y: "0px", viewBox: "0 0 200 200", enableBackground: "new 0 0 200 200", xmlSpace: "preserve" }, props, { children: [(0, jsx_runtime_1.jsx)("g", { children: (0, jsx_runtime_1.jsx)("rect", { fill: "#154A99", width: 200, height: 200 }) }), (0, jsx_runtime_1.jsxs)("g", { children: [(0, jsx_runtime_1.jsx)("path", { fill: "#FFFFFF", d: "M65,61.7C54.2,61.7,52.8,72.1,52.7,80c0,7.9,1.3,18.5,12.1,18.5C75.5,98.5,77,88,77,80.1 C77.1,72.2,75.7,61.8,65,61.7 M64.6,119.1c-27.9-0.2-40.4-18.7-40.2-39.3C24.5,59.2,37.2,41,65.1,41.1 c27.9,0.2,40.4,18.5,40.2,39.1C105.2,100.9,92.5,119.2,64.6,119.1" }), (0, jsx_runtime_1.jsx)("path", { fill: "#FFFFFF", d: "M135.2,62.2C124.5,62.1,123,72.6,123,80.4c-0.1,7.9,1.3,18.5,12,18.5c10.7,0.1,12.2-10.5,12.3-18.4 C147.3,72.7,146,62.2,135.2,62.2 M134.9,119.5c-27.9-0.2-40.4-18.7-40.2-39.3c0.1-20.6,12.8-38.8,40.7-38.7 c27.9,0.2,40.4,18.5,40.3,39.1C175.5,101.3,162.8,119.7,134.9,119.5" }), (0, jsx_runtime_1.jsx)("path", { fill: "#FFFFFF", d: "M35,118.6c20.1,9,41,13,63.7,13.1c23.9,0.1,41.9-3.6,64.8-12.3l-0.1,24.7c-20.5,10.2-42.7,14.9-64.9,14.8 c-24.2-0.1-41.6-5.1-63.7-15.5L35,118.6z M35,118.6" })] })] })));
}
exports.default = Svg;
