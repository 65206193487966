"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
function Svg(props) {
    return ((0, jsx_runtime_1.jsxs)("svg", __assign({ id: "Layer_1", xmlns: "http://www.w3.org/2000/svg", height: "100%", width: "100%", xmlnsXlink: "http://www.w3.org/1999/xlink", x: "0px", y: "0px", viewBox: "0 0 200 200", style: {
            enableBackground: "new 0 0 200 200",
        }, xmlSpace: "preserve" }, props, { children: [(0, jsx_runtime_1.jsx)("rect", { x: 0, style: {
                    fill: "#384B5D",
                }, width: 200, height: 200 }), (0, jsx_runtime_1.jsxs)("g", { children: [(0, jsx_runtime_1.jsx)("path", { style: {
                            fill: "#FFFFFF",
                        }, d: "M170.3,112.6c-2.3,3-5.4,4.1-8.8,4.2c-1.5,0.1-3,0-4.5-0.2c-1.6-0.2-3.1-0.9-4.5-1.7 c-4.7-2.7-7.6-6.8-8.4-12.2c-1.5-9.6,5.1-17.8,13.7-19.3c8.5-1.5,16.5,3.4,19.2,11.2c0.6,1.7,0.9,3.5,0.9,5.3c0,4.7,0,9.4,0,14.1 c0,0.6,0,1.2-0.1,1.8c-0.1,0.6-0.4,1-1,1c-1.7,0-3.5,0-5.2,0c-0.7,0-1.2-0.5-1.2-1.3C170.3,114.6,170.3,113.7,170.3,112.6z  M160.8,109.3c4.1,0.2,9.5-3.1,9.4-9.2c0-5.9-5-9.4-9.2-9.4c-4.7-0.1-9.6,3.7-9.6,9.4C151.4,105.7,156.3,109.4,160.8,109.3z" }), (0, jsx_runtime_1.jsx)("path", { style: {
                            fill: "#FFFFFF",
                        }, d: "M57.4,116.9c-0.4,0-0.7,0-1.1,0c-10.6,0-21.3,0-31.9,0c-0.5,0-1.2,0.2-1.6-0.4 c-0.5-0.7-0.8-1.5-0.6-2.4c0.1-0.8,0.5-1.5,1-2.1c1.1-1.3,2.2-2.7,3.3-4c4.6-5.4,9.2-10.7,13.7-16.1c0.1-0.2,0.3-0.3,0.4-0.5 c0.2-0.3,0.1-0.7-0.3-0.7c-0.2,0-0.5,0-0.7,0c-5,0-10,0-15,0c-0.3,0-0.7,0-1-0.1c-0.7-0.2-1.1-0.7-1.1-1.3c0-1.6,0-3.1,0-4.7 c0-0.8,0.6-1.3,1.4-1.4c0.2,0,0.4,0,0.6,0c9.1,0,18.3,0,27.4,0c0.4,0,0.8,0,1.2,0.1c0.4,0.1,0.7,0.4,0.7,0.9c0,1.1,0,2.2,0,3.3 c0,0.4-0.2,0.6-0.4,0.8c-2.4,2.8-4.8,5.6-7.2,8.4c-3.3,3.8-6.6,7.7-9.8,11.5c-0.2,0.3-0.6,0.5-0.4,0.9c0.2,0.4,0.6,0.3,0.9,0.3 c5.4,0,10.8,0,16.1,0c0.1,0,0.2,0,0.3,0c0.7,0,0.7,0,1,0.7c0.4,1.2,0.8,2.5,1.3,3.7c0.3,0.8,0.8,1.4,1.5,2 C57.5,116.2,57.5,116.2,57.4,116.9z" }), (0, jsx_runtime_1.jsx)("path", { style: {
                            fill: "#FFFFFF",
                        }, d: "M106.1,83.1c8.9-0.1,16.9,7.2,16.9,17c-0.1,9.6-8.1,16.9-17,16.8c-8.9-0.1-16.8-7.2-16.7-17 C89.2,90.1,97.4,82.9,106.1,83.1z M106.1,90.5c-4.5-0.2-9.5,3.6-9.5,9.5c0,5.4,4.4,9.4,9.4,9.4c4.6,0,9.5-3.5,9.5-9.4 C115.6,93.9,110.4,90.4,106.1,90.5z" }), (0, jsx_runtime_1.jsx)("path", { style: {
                            fill: "#FFFFFF",
                        }, d: "M78.6,112.8c-1.5,2.5-3.9,3.3-6.5,3.7c-1.8,0.3-3.7,0.4-5.5,0.3c-1.2,0-2.4-0.2-3.5-0.5 c-2.7-0.8-4.5-2.5-5.5-5.2c-0.8-2.1-1-4.3-1-6.5c0-6.6,0-13.2,0-19.9c0-0.2,0-0.3,0-0.5c0.1-0.7,0.5-1.1,1.2-1.1c1.7,0,3.5,0,5.2,0 c0.6,0,1,0.3,1.1,0.9c0.1,0.3,0.1,0.6,0.1,0.9c0,5.8,0,11.7,0,17.5c0,1.1,0.1,2.2,0.3,3.2c0.6,2.3,2,3.5,4.4,3.7 c1.5,0.1,3.1-0.1,4.5-0.7c1.9-0.7,3.2-2.1,4-3.9c0.8-1.7,1.1-3.6,1.1-5.4c0.1-4.8,0-9.6,0-14.4c0-1.6,0.3-1.9,1.9-1.9 c1.4,0,2.8,0,4.2,0c1,0,1.4,0.4,1.4,1.4c0,0.2,0,0.3,0,0.5c0,10,0,20,0,30c0,0.3,0,0.7-0.1,1c-0.1,0.5-0.5,0.8-1,0.8 c-1.8,0-3.6,0-5.4,0c-0.6,0-1-0.5-1.1-1.2c0-0.7,0-1.4,0-2C78.6,113.3,78.6,113.1,78.6,112.8z" }), (0, jsx_runtime_1.jsx)("path", { style: {
                            fill: "#FFFFFF",
                        }, d: "M133.2,87.1c0.1-0.1,0.2-0.1,0.2-0.2c0.8-1.3,1.9-2.1,3.3-2.7c2.3-0.9,4.6-1.2,7.1-1.2 c0.8,0,1.2,0.6,1.1,1.4c-0.2,1.7-0.4,3.5-0.6,5.2c-0.1,0.9-0.5,1.2-1.3,1.2c-0.8,0-1.6,0-2.3,0.2c-2.5,0.5-4.2,2.1-5.4,4.2 c-1.1,1.9-1.8,4-2,6.3c-0.1,1-0.1,2.1-0.1,3.2c0,3.5,0,6.9,0,10.4c0,0.2,0,0.4,0,0.6c0,0.7-0.4,1.1-1.2,1.2c-0.9,0-1.8,0-2.8,0 c-0.8,0-1.5,0-2.3,0c-0.9,0-1.2-0.4-1.3-1.2c0-0.2,0-0.4,0-0.6c0-10,0-20,0-30c0-1.3,0.2-2,1.9-1.9c1.4,0.1,2.8,0.1,4.2,0 c0.8,0,1.4,0.5,1.4,1.3c0,0.7,0,1.4,0,2.1C133.1,86.7,133.1,86.9,133.2,87.1z" })] })] })));
}
exports.default = Svg;
