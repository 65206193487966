"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
function Svg(props) {
    return ((0, jsx_runtime_1.jsx)("svg", __assign({ id: "Layer_1", xmlns: "http://www.w3.org/2000/svg", height: "100%", width: "100%", xmlnsXlink: "http://www.w3.org/1999/xlink", x: "0px", y: "0px", viewBox: "0 0 200 200", enableBackground: "new 0 0 200 200", xmlSpace: "preserve" }, props, { children: (0, jsx_runtime_1.jsxs)("g", { children: [(0, jsx_runtime_1.jsx)("circle", { fill: "#E1393F", cx: 100, cy: 100, r: 90 }), (0, jsx_runtime_1.jsx)("path", { fill: "#FFFFFF", d: "M106.1,45.6H93.4v63.2h12.6V45.6z M99.7,146.8c-24.4-0.1-44.2-19.9-44.1-44.3c0-20,13.5-37.4,32.8-42.6 v13.1C72.1,79.2,64,97.5,70.3,113.8s24.6,24.4,40.9,18.1c16.3-6.3,24.4-24.6,18.1-40.9c-3.2-8.3-9.8-14.9-18.1-18.1V59.8 c23.6,6.3,37.6,30.6,31.2,54.2C137.2,133.3,119.7,146.7,99.7,146.8z" })] }) })));
}
exports.default = Svg;
