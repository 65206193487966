"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
function Svg(props) {
    return ((0, jsx_runtime_1.jsxs)("svg", __assign({ width: "100%", height: "100%", viewBox: "0 0 250 250", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props, { children: [(0, jsx_runtime_1.jsx)("path", { d: "M124.929 172.889C98.5201 172.889 77.1112 151.48 77.1112 125.071C77.1112 98.6627 98.5201 77.2543 124.929 77.2543C151.337 77.2543 172.746 98.6627 172.746 125.071C172.746 151.48 151.337 172.889 124.929 172.889ZM124.929 60.1426C89.0697 60.1426 60 89.2128 60 125.071C60 160.931 89.0697 190 124.929 190C160.788 190 189.857 160.931 189.857 125.071C189.857 89.2128 160.788 60.1426 124.928 60.1426H124.929Z", fill: "#CF272D" }), (0, jsx_runtime_1.jsx)("path", { d: "M127.598 108.901C127.598 101.445 133.642 95.4004 141.099 95.4004C148.555 95.4004 154.599 101.445 154.599 108.901C154.599 116.357 148.555 122.401 141.099 122.401C133.642 122.401 127.598 116.357 127.598 108.901ZM127.598 141.241C127.598 133.785 133.642 127.74 141.099 127.74C148.555 127.74 154.599 133.785 154.599 141.241C154.599 148.697 148.555 154.742 141.099 154.742C133.642 154.742 127.598 148.697 127.598 141.241ZM95.2578 141.241C95.2578 133.785 101.303 127.74 108.759 127.74C116.215 127.74 122.259 133.785 122.259 141.241C122.259 148.697 116.215 154.742 108.759 154.742C101.303 154.742 95.2578 148.697 95.2578 141.241ZM95.2578 108.901C95.2578 101.445 101.303 95.4009 108.759 95.4009C116.215 95.4009 122.259 101.445 122.259 108.901C122.259 116.358 116.215 122.402 108.759 122.402C101.303 122.402 95.2578 116.358 95.2578 108.901Z", fill: "#CF272D" })] })));
}
exports.default = Svg;
